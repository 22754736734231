@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

body * {
  font-family: 'Nanum Gothic', sans-serif;
}

.search-box {
  width: 30vw;
  min-width: 280px;
}

.header {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
}
.header .header-content {
  max-height: fit-content;
}
.header .header-pusher {
  flex: 0;
  transition: flex 1s;
}
.header .header-pusher img {
  max-width: 200px;
  display: none;
}
.header.active .header-pusher {
  flex: auto;
}
.header.active .header-pusher img {
  display: inherit;
}
.header .header-content h1 {
  font-family: 'Nanum Pen Script', cursive;
  font-size: 4rem;
  text-align: center;
}

.header #map-container {
  display: none;
}
.header.active #map-container {
  display: block;
}
#map-container .review-header {
  width: 100%;
  padding: 10px;
}

.header:not(.active) .md:block {
  display: none;
}

#address {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

#map {
  height: 300px;
  width: 500px;
  margin-bottom: 50px;
}

.review {
  width: 100%;
  margin-bottom: 50px;
}
.review > * {
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

#construction-container {
  padding: 0 10px 50px 10px;
  display: none;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@-webkit-keyframes swinging {
  0% {
    -webkit-transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
  }
}
@keyframes swinging {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@-webkit-keyframes sleeping {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sleeping {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#swinger {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  transform-box: fill-box;
  -webkit-animation: swinging 3.5s ease-in-out forwards infinite;
  animation: swinging 3.5s ease-in-out forwards infinite;
}

#zone {
  opacity: 0;
  -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
  animation: sleeping 3.5s ease-in-out forwards infinite;
}

#ztwo {
  opacity: 0;
  -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
  animation: sleeping 3.5s ease-in-out forwards infinite;
  animation-delay: 0.4s;
  -website-animation-delay: 0.4s;
}

#zthree {
  opacity: 0;
  -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
  animation: sleeping 3.5s ease-in-out forwards infinite;
  animation-delay: 0.7s;
  -website-animation-delay: 0.7s;
}

#zfour {
  opacity: 0;
  -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
  animation: sleeping 3.5s ease-in-out forwards infinite;
  animation-delay: 1s;
  -website-animation-delay: 1s;
}

#zfive {
  opacity: 0;
  -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
  animation: sleeping 3.5s ease-in-out forwards infinite;
  animation-delay: 1.3s;
  -website-animation-delay: 1.3s;
}

svg {
  max-width: 1000px;
}

.st0 {
  fill: #e6e7e8;
}

.st1 {
  fill: #d1d3d4;
}

.st2 {
  fill: #f4f4f4;
}

.st3 {
  fill: #bcbec0;
}

.st4 {
  fill: #231f20;
}

.st5 {
  fill: #ffffff;
}

.st6 {
  fill: #d36866;
}

.st7 {
  fill: #c2c4c6;
}

.st8 {
  fill: #dbdbdb;
}

.st9 {
  fill: #a7a9ac;
}

.st10 {
  fill: #808285;
}

button.hover\:underline:hover {
  text-decoration: underline;
}
button.text-blue-500 {
  color: rgba(59, 130, 246, 1);
}
